<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="py-9 px-8">
      <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
        <div class="mb-8 mb-sm-0">
          <span class="d-block">Id: {{ workflowItem.id }}</span>
          <span class="d-block">StepId: {{ workflowItem.stepId }}</span>
          <span class="d-block">Active: {{ workflowItem.active }}</span>
          <span class="d-block">SleepUntil: {{ workflowItem.sleepUntil }}</span>
          <span class="d-block">PersistenceData: {{ workflowItem.persistenceData }}</span>
          <span class="d-block">StartTime: {{ workflowItem.startTime }}</span>
          <span class="d-block">EndTime: {{ workflowItem.endTime }}</span>
          <span class="d-block">PredecessorId: {{ workflowItem.predecessorId }}</span>
          <span class="d-block">Status: {{ workflowItem.status }}</span>
          <span class="d-block">ExtensionAttributes: </span>
          <span v-for="(item, index) in workflowItem.extensionAttributes" :key="index"
                class="d-block"> {{ item.key }}: {{ item.value }}</span>
        </div>
        <div>
          <p class="mb-2">
            <span>EventKey: {{ workflowItem.eventKey }}</span>
          </p>
          <p class="mb-2">
            <span>EventPublished: {{ workflowItem.eventPublished }}</span>
          </p>
          <p class="mb-2">
            <span>EventData: {{ workflowItem.eventData }}</span>
          </p>
          <p class="mb-2">
            <span>StepName: {{ workflowItem.stepName }}</span>
          </p>
          <p class="mb-2">
            <span>RetryCount: {{ workflowItem.retryCount }}</span>
          </p>
          <p class="mb-2">
            <span>ContextItem: {{ workflowItem.contextItem }}</span>
          </p>
          <p class="mb-2">
            <span>Outcome: {{ workflowItem.outcome }}</span>
          </p>
          <p class="mb-2">
            <span>Scope: {{ workflowItem.scope }}</span>
          </p>
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
export default {
  name: 'OrderWorkflowItem',
  props: ['workflowItem'],
}
</script>
