<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-divider></v-divider>

    <v-card-text class="py-9 px-8">
      <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
        <div class="mb-8 mb-sm-0">
          <p class="font-weight-semibold payment-details-header">
            {{ dish.name }}
          </p>
          <table>
            <tr>
              <td class="pe-6">
                Количество:
              </td>
              <td>
                {{ dish.amount }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Цена:
              </td>
              <td>
                {{ dish.price }}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <v-img
            :src="dish.image"
            max-height="50px"
            max-width="50px"
            contain
            class="me-3"
          ></v-img>
        </div>
      </div>
    </v-card-text>

    <v-data-table :v-if="dish.modifiers"
                  :headers="modifierHeaders"
                  :items="dish.modifiers"
                  show-expand
                  :expanded.sync="expanded"
                  :hide-default-footer="true"
    >
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.amount`]="{item}">
        {{ item.amount }}
      </template>

      <template #[`item.price`]="{item}">
        {{ item.price }}
      </template>

      <template
        v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
      >
        <v-icon
          v-if="item.combos && item.combos.length > 0"
          :class="[
            'v-data-table__expand-icon',
            { 'v-data-table__expand-icon--active': isExpanded },
          ]"
          @click.stop="expand(!isExpanded)"
        >$expand
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <order-dish-form v-for="(dish, index) in item.combos" :key="index" :dish="dish"></order-dish-form>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'OrderDishForm',
  props: ['dish'],
  data: {
    expanded: []
  },
  computed: {
    modifierHeaders() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Количество', value: 'amount' },
        { text: 'Цена', value: 'price' },
      ]
    },
  },
}
</script>
