<template>
  <div>
    <v-card id="promotion-list">
      <v-data-table
        :headers="headers"
        :items="logs"
        class="text-no-wrap"
      >
        <template #[`item.source`]="{item}">
          {{ item.source }}
        </template>

        <template #[`item.creationTime`]="{item}">
          {{ item.creationTime }}
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="showLog(item.request, item.response)"
                >
                  <v-icon size="18">
                    {{ icons.mdiEye }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Просмотр</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <order-log-form :show="toShowOrderLogForm" :request="request" :response="response" :cancellation="formCancel"></order-log-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrderLogForm from './order-log-form.vue'
import {
  mdiTrendingUp,
  mdiCellphone,
  mdiWeb,
  mdiEye,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'

export default {
  name: 'OrderList',
  components: { OrderLogForm },
  data: () => ({
    toShowOrderLogForm: false,
    request: '',
    response: '',
    icons: {
      mdiCellphone,
      mdiWeb,
      mdiEye,
      mdiTrendingUp,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
  }),
  computed: {
    ...mapState('orderLogs', {
      logs: 'list',
    }),
    headers() {
      return [
        { text: 'Источник', value: 'source' },
        { text: 'Дата', value: 'creationTime' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    const { id } = this.$route.params
    this.fetchOrders(id)
  },
  mounted() {
  },
  methods: {
    ...mapActions('orderLogs', { fetchOrders: 'fetchList' }),
    showLog(request, response){
      this.request = request;
      this.response = response;
      this.toShowOrderLogForm = true;
    },
    formCancel(){
      this.toShowOrderLogForm = false;
    }
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
