<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="py-9 px-8">
      <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
        <!-- Left Content -->
        <div class="mb-8 mb-sm-0">
          <div class="d-flex align-center mb-6">
            <v-img
              :src="logo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
            <span class="text--primary font-weight-bold text-xl">
                  Империя Пиццы
            </span>
          </div>
          <span class="d-block">Гость: {{ guest }}</span>
          <span class="d-block">Телефон: {{ phoneNumber }}</span>
          <span class="d-block">Источник: {{ getSource(source) }}</span>
          <span class="d-block">Сумма: {{ sum }}</span>
          <span class="d-block">К оплате: {{ change }}</span>
          <span :style="{'color': isCreatedInRk ? 'green' : 'red'}" class="d-block">Идентификатор Rk7: {{rk7Id }}</span>
        </div>
        <div>
          <p class="font-weight-medium text-xl text--primary mb-4">
            Заказ #{{ id }}
          </p>
          <p class="mb-2">
            <span>Дата заказа: {{ orderDate }}</span>
          </p>
          <p class="mb-2">
            <span>Ресторан: {{ restaurant }}</span>
          </p>
          <p class="mb-2">
            <span>Филиал: {{ branch }}</span>
          </p>
          <p class="mb-2">
            <span>Тип заказа: {{ getOrderType(orderType) }}</span>
          </p>
          <p class="mb-2">
            <span>Тип оплаты: {{ paymentMethod }}</span>
          </p>
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="py-9 px-8">
      <div v-if="address !== null"
           class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
        <div class="mb-8 mb-sm-0">
          <span class="d-block">Город: {{ address.city }}</span>
          <span class="d-block">Улица: {{ address.street }}</span>
          <span class="d-block">Здание: {{ address.building }}</span>
          <span class="d-block">Дом: {{ address.house }}</span>
          <span class="d-block">Код домофона: {{ address.entryCode }}</span>
          <span class="d-block">Этаж: {{ address.floor }}</span>
          <span class="d-block">Квартира: {{ address.entry }}</span>
        </div>
      </div>
    </v-card-text>
    <dish-form v-for="(item, index) in dishes" :dish="item" :key="index"></dish-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DishForm from './order-dish-form'

export default {
  name: 'OrderForm',
  components: { DishForm },
  async created() {
    const { id } = this.$route.params
    await this.fetchById(id)
    this.logo = require('@/assets/images/logos/pizza-empire.png')
  },
  methods: {
    ...mapActions('order/form', ['fetchById']),
    getOrderType(orderType) {
      if (orderType === 'Delivery') {
        return 'Доставка'
      }
      return 'На вынос'
    },
    getSource(source) {
      if (source === 'MobileApp') {
        return 'Мобильное приложение'
      }
      return 'WEB Сайт'
    },
  },
  data: () => ({
    logo: '',
  }),
  computed: {
    ...mapFormHandlers('order/form', [
      'id',
      'dishes',
      'guest',
      'change',
      'branch',
      'address',
      'restaurant',
      'rk7Id',
      'isCreatedInRk',
      'sum',
      'paymentMethod',
      'orderType',
      'source',
      'phoneNumber',
      'orderDate',
    ]),
  },
}
</script>
