var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-divider'),_c('v-card-text',{staticClass:"py-9 px-8"},[_c('div',{staticClass:"payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row"},[_c('div',{staticClass:"mb-8 mb-sm-0"},[_c('p',{staticClass:"font-weight-semibold payment-details-header"},[_vm._v(" "+_vm._s(_vm.dish.name)+" ")]),_c('table',[_c('tr',[_c('td',{staticClass:"pe-6"},[_vm._v(" Количество: ")]),_c('td',[_vm._v(" "+_vm._s(_vm.dish.amount)+" ")])]),_c('tr',[_c('td',{staticClass:"pe-6"},[_vm._v(" Цена: ")]),_c('td',[_vm._v(" "+_vm._s(_vm.dish.price)+" ")])])])]),_c('div',[_c('v-img',{staticClass:"me-3",attrs:{"src":_vm.dish.image,"max-height":"50px","max-width":"50px","contain":""}})],1)])]),_c('v-data-table',{attrs:{"v-if":_vm.dish.modifiers,"headers":_vm.modifierHeaders,"items":_vm.dish.modifiers,"show-expand":"","expanded":_vm.expanded,"hide-default-footer":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.combos && item.combos.length > 0)?_c('v-icon',{class:[
          'v-data-table__expand-icon',
          { 'v-data-table__expand-icon--active': isExpanded } ],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.combos),function(dish,index){return _c('order-dish-form',{key:index,attrs:{"dish":dish}})}),1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }