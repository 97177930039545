<template>
  <v-card>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          @click="suspend()"
        >
          <span>Остановить</span>
        </v-btn>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <order-workflow-item v-for="(item, index) in workflowItems" :workflow-item="item"
                         :key="index"></order-workflow-item>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrderWorkflowItem from '@/views/order/order-workflow-item.vue'

export default {
  name: 'OrderWorkflowItemList',
  components: { OrderWorkflowItem },
  async mounted() {
    const { id } = this.$route.params
    await this.fetchWorkflows(id)
  },
  computed: {
    ...mapState('orderWorkflowItems', {
      workflowItems: 'list',
    }),
  },
  methods: {
    ...mapActions('orderWorkflowItems', { fetchWorkflows: 'fetchList' }),
    ...mapActions('orderLogs', { suspendWorkFlow: 'suspendWorkFlow' }),
    async suspend() {
      const { id } = this.$route.params
      await this.suspendWorkFlow(id)
    },
  },
}
</script>
