var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"id":"promotion-list"}},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.logs},scopedSlots:_vm._u([{key:"item.source",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source)+" ")]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.creationTime)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.showLog(item.request, item.response)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])])],1)]}}],null,true)})],1),_c('order-log-form',{attrs:{"show":_vm.toShowOrderLogForm,"request":_vm.request,"response":_vm.response,"cancellation":_vm.formCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }