<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiClipboardOutline }}
        </v-icon>
        <span>Данные</span>
      </v-tab>

      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiCardTextOutline }}
        </v-icon>
        <span>Логи</span>
      </v-tab>

      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiContactlessPayment }}
        </v-icon>
        <span>Workflow</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <order-form></order-form>
      </v-tab-item>
      <v-tab-item>
        <order-log-list></order-log-list>
      </v-tab-item>
      <v-tab-item>
        <order-workflow-item-list></order-workflow-item-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiClipboardOutline,
  mdiCardTextOutline,
  mdiContactlessPayment
} from '@mdi/js'
import OrderForm from '@/views/order/order-form.vue'
import OrderLogList from './order-log-list.vue'
import OrderWorkflowItemList from '@/views/order/order-workflow-item-list.vue'

export default {
  name: 'OrderFormTab',
  components: {
    OrderWorkflowItemList,
    OrderForm,
    OrderLogList
  },
  data: () => ({
    icons: {
      mdiClipboardOutline,
      mdiCardTextOutline,
      mdiContactlessPayment
    },
    tab: null,
  }),
}
</script>
