<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        Лог
      </v-card-title>
      <v-card-text>
        <v-form
          class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="request"
                label="Запрос"
                :auto-grow="true"
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="response"
                label="Ответ"
                dense
                :auto-grow="true"
                outlined
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'OrderLogForm',
  props: ['show', 'cancellation', 'request', 'response'],
  methods: {
    cancel() {
      this.cancellation()
    },
  },
}
</script>
